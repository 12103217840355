export const URL_API = {
  AUTHENTICATION: {
    LOGIN: "authentication/login",
  },
  UPDATE_PROFILE: "crm-users",
  USER_INFORMATION: "crm-users/id",
  ROLE: {
    POST_ROLE: "crm-roles",
    GET_ALL_PERMISSIONS: "crm-roles/get-permissions",
    GET_ALL_ROLES: "crm-roles/get-roles",
    GET_ROLES: "crm-roles/get-roles",
    GET_ROLE: "crm-roles/id",
    PATCH_ROLE: "crm-roles",
    DELETE_ROLES: "crm-roles/delete-roles",
    GET_USERS_BY_ROLE_ID: "role/get-users-by-roleId",
  },
  CRM_SETTINGS: {
    ADD_SETTINGS: "crm-settings/add-settings",
    GET_SETTINGS: "crm-settings/get-settings",
    UPLOAD_LOGO: "crm-settings/upload-logo",
    UPLOAD_FAVICON: "crm-settings/upload-favicon",
  },
  CRM_USERS: {
    POST_USER: "crm-users/create-user",
    GET_USERS: "crm-users/get-users",
    GET_ALL_USERS: "crm-users/get-users",
    GET_ALL_RESELLERS_FREE: "crm-users/get-reseller-free",
    GET_USER: "crm-users/id",
    PATCH_USER: "crm-users/update-user",
    DELETE_USERS: "crm-users/delete-users",
    BLOCK_USERS: "crm-users/block-user",
    UNBLOCK_USERS: "crm-users/unblock-user",
    VERIFY_PHONE_USERS: "crm-users/send-verify-phone",
    VERIFY_MAIL_USERS: "crm-users/send-verify-mail",
    VERIFY_USER_CODE: "crm-users/verify-user-code",
    RESET_PASSWORD: "crm-users/reset-password",
    INTRODUCE_SALE: "crm-users/send-introduce",
    SET_PASSWORD: "crm-users/set-password",
    UPDATE_PASSWORD: "crm-users/update-password",
    REGISTER_RESELLER: "crm-users/register-reseller",
    APPROVE_REJECT_RESELLER: "crm-users/approve-reject-reseller",
    GET_POTENTIAL_RESELLERS: "crm-users/get-potential-resellers",
    COUNT_POTENTIAL_RESELLERS_BY_STATUS: "crm-users/count-resellers-by-status",
    GET_POTENTIAL_RESELLER: "crm-users/get-potential-resellers/id",
    GET_NEW_PASSWORD: "crm-users/get-new-password",
    SEND_FORGOT_PASSWORD: "crm-users/send-forgot-password",
    GET_RESELLER_BY_USER_ID: "crm-users/get-infor-reseller/userId",
    PRESIGNED_URL: "crm-users/presigned-url",
    PRESIGNED_RESELLER_FILES: "crm-users/presigned-reseller-files",
    DELETE_PROFILE_PICTURE: "crm-users/delete-profile-picture",
  },
  CRM_LEADS: {
    POST_LEAD: "crm-leads",
    GET_LEADS: "crm-leads/get-leads",
    GET_LEAD: "crm-leads",
    VALIDATE_LEAD: "crm-leads/validate-duplicate",
    PATCH_UPDATE_LEAD: "crm-leads",
    DELETE_LEADS: "crm-leads/delete-leads",
  },
  CRM_NEWS: {
    POST_NEWS: "crm-blogs",
    GET_NEWS_BY_ID: "crm-blogs",
    PATCH_UPDATE_NEWS: "crm-blogs",
    GET_NEWS: "crm-blogs/get-blogs",
    DELETE_NEWS: "crm-blogs/delete-blogs",
    GET_LINK_S3: "crm-blogs/get-link-s3",
  },
  CRM_PRODUCTS: {
    POST_PRODUCT: "crm-products",
    GET_PRODUCTS: "crm-products/get-products",
    GET_PRODUCT: "crm-products",
    PATCH_UPDATE_PRODUCT: "crm-products",
    DELETE_PRODUCTS: "crm-products/delete-products",
  },
  CRM_NOTIFICATIONS: {
    POST_NOTIFICATION: "crm-notifications",
    GET_NOTIFICATIONS: "crm-notifications/get-notifications",
    GET_NOTIFICATION: "crm-notifications",
    PATCH_UPDATE_NOTIFICATION: "crm-notifications",
    DELETE_NOTIFICATIONS: "crm-notifications/delete-notifications",
    SEEN_NOTIFICATIONS: "crm-notifications/seen-notifications",
    GET_MY_LOGS: "crm-notifications/get-my-logs",
    GET_UNREAD: "crm-notifications/get-un-read",
  },
  CRM_ADD_ONS: {
    POST_ADD_ON: "crm-add-ons",
    GET_ADD_ONS: "crm-add-ons/get-add-ons",
    GET_ADD_ON: "crm-add-ons",
    PATCH_UPDATE_ADD_ON: "crm-add-ons",
    DELETE_ADD_ONS: "crm-add-ons/delete-add-ons",
  },
  CRM_TICKETS: {
    GET_LINK_TICKET_S3: "crm-tickets/get-link-s3",
    POST_TICKET: "crm-tickets",
    GET_TICKETS: "crm-tickets/get-tickets",
    GET_TICKET: "crm-tickets",
    PATCH_UPDATE_TICKET: "crm-tickets",
    DELETE_TICKETS: "crm-tickets/delete-tickets",
    DELETE_FILES: "crm-tickets/delete-files",
  },
  CRM_COMMENT: {
    GET_LINK_COMMENT_S3: "crm-comments/get-link-s3",
    GET_COMMENTS: "crm-comments/get-comments",
    POST_COMMENT: "crm-comments",
    DELETE_COMMENT: "crm-comments/delete-comments",
    DELETE_FILE_COMMENT: "crm-comments/delete-file",
    PATCH_COMMENT: "crm-comments",
  },
  CRM_ORDERS: {
    POST_ORDER: "crm-orders",
    GET_PO: "crm-orders",
    GET_ORDER: "crm-orders",
    PATCH_ORDER: "crm-orders",
    UPDATE_TRACKING_NO: "crm-orders/update-tracking-no",
    UPDATE_ORDER_IMAGES: "crm-orders/update-order-images",
    CALCULATE_INVOICE: "crm-orders/calculate-invoice",
    SEND_INVOICE: "crm-orders/send-invoice",
    UPDATE_ORDER_DETAILS: "crm-orders/update-detail",
    GET_ORDER_PAYMENT: "crm-orders/order-payment",
    CHECK_LAST_OFFER: "crm-orders/get-last-offer",
    CHECK_MULTIPLE_LAST_OFFER: "crm-orders/get-multiple-last-offer",
    DELETE_ORDERS: "crm-orders/delete-orders",
    GET_TOTAL_BY_DATE: "crm-orders/get-total-by-date",
    GET_TOP_REVENUE_RESELLER: "crm-orders/get-top-revenue-reseller",
    GET_TOP_LICENSE_RESELLER: "crm-orders/get-top-license-reseller",
    GET_TOP_ORDER_RESELLER: "crm-orders/get-top-order-reseller",
    GET_CHART_REVENUE_MONTH: "crm-orders/get-chart-revenue-month",
    GET_CHART_LICENSE_MONTH: "crm-orders/get-chart-license-month",
    GET_CHART_ORDER_MONTH: "crm-orders/get-chart-order-month",
    GET_CHART_REVENUE_WEEK: "crm-orders/get-chart-revenue-week",
    GET_CHART_LICENSE_WEEK: "crm-orders/get-chart-license-week",
    GET_CHART_ORDER_WEEK: "crm-orders/get-chart-order-week",
  },
  S3: {
    PUT_FILE_TO_S3: "PUT_FILE_TO_S3",
  },
  PO: {
    GET_PO: "crm-orders/get-orders",
    PRINT_PO: "crm-orders/print-pdf",
    CREATE_DRAFT_PO: "crm-orders",
    GET_PO_BY_ID: "crm-orders",
    PO_UPDATE_STATUS: "crm-orders/update-status",
    UPDATE_INFO_PO: "crm-orders/update-order",
    DELETE_DRAFT_PO: "crm-orders/delete-orders",
  },
  USER_INTERACTS: {
    GET_USER_INTERACTS: "crm-user-interacts/get-user-interacts",
    GET_USER_INTERACT: "crm-user-interacts",
  },
  CRM_DISCOUNT: {
    POST_DISCOUNT: "crm-discounts",
    GET_DISCOUNTS: "crm-discounts/get-discounts",
    GET_DISCOUNT: "crm-discounts",
    PATCH_ACTIVE_DISCOUNT: "crm-discounts",
    POST_DISCOUNT_BY_CODE: "crm-discounts/get-discount-by-code",
  },
  CMS_EXCELS: {
    IMPORT: "cms-excels/import",
    EXPORT: "cms-excels/export",
    EXPORT_DASHBOARD: "cms-excels/export-dash-board",
  },
};
